<template>
  <v-footer absolute bottom class="subtitle-1 grey lighten-3">
    <v-container
      class="d-flex"
      align=""
      :class="{ 'flex-column align-start': $vuetify.breakpoint.smAndDow }"
    >
      <v-row align="center" justify="center">
        <v-col
          :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
          cols="12"
          md="3"
        >
          <div class="dark-grey-text bold-text">
            <div class="text-hover-primary">
              2021 &nbsp;&copy;&nbsp;
              <a
                href="https://testapp.io"
                target="_blank"
                class="text-muted text-hover-primary"
              >
                TestApp.io
              </a>
            </div>
            <small>
              Latest release: {{ getLatestVersion }} -
              <span
                ><vue-hoverable-date
                  :date="getLatestReleaseTimestamp"
                ></vue-hoverable-date
              ></span>
            </small>
          </div>
        </v-col>
        <v-col
          :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-right'"
          cols="12"
          md="9"
        >
          <div class="bold-text">
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=testapp.io"
                class="text-muted text-hover-primary ml-3"
                target="_blank"
              >
                Android App
              </a>
              <a
                href="https://apps.apple.com/us/app/testapp-io/id1518972541"
                class="text-muted text-hover-primary ml-3"
                target="_blank"
              >
                iOS App
              </a>
              <a
                href="https://roadmap.testapp.io/"
                class="text-muted text-hover-primary ml-3"
                target="_blank"
              >
                Roadmap
              </a>
              <a
                href="https://testapp.io/terms-and-conditions"
                class="text-muted text-hover-primary mx-3"
                target="_blank"
              >
                Terms & Conditions
              </a>
              <a
                href="https://testapp.io/privacy-policy"
                class="text-muted text-hover-primary mx-3"
                target="_blank"
              >
                Privacy Policy
              </a>
              <a
                href="https://testapp.io/contact"
                class="text-muted text-hover-primary ml-3"
                target="_blank"
              >
                Contact
              </a>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: "KTFooter",
  computed: {
    getLatestVersion() {
      return "v" + process.env.VUE_APP_RELEASE.split("@")[1];
    },
    getLatestReleaseTimestamp() {
      return process.env.VUE_APP_RELEASE_DATE;
    },
  },
};
</script>
